import React from 'react'
import Section from '../../molecules/Section'
import Button from '../../atoms/Button'
import styles from './SectionPartnerProgram.module.css'
import { CheckList } from '../../atoms/Lists'

interface SectionPartnerProgramProps {
  title: string
  description: string
  types: {
    title: string
    description: string
  }[]
  benefits: {
    title: string
    description: string
    list: string[]
    button: string
  }
}

export default function SectionPartnerProgram({
  program,
  toggleModal
}: {
  program: SectionPartnerProgramProps
  toggleModal: () => void
}) {
  return (
    <Section
      title={program.title}
      description={program.description}
      className={styles.section}
      id="servicepartner"
      headerCenter
    >
      <ul className={styles.types}>
        {program.types.map((type) => (
          <li className={styles.type} key={type.title}>
            <h3>{type.title}</h3>
            <p>{type.description}</p>
          </li>
        ))}
      </ul>

      <div className={styles.benefits}>
        <div>
          <h3>{program.benefits.title}</h3>
          <p>{program.benefits.description}</p>
          <Button style="primary" onClick={toggleModal}>
            {program.benefits.button}
          </Button>
        </div>

        <CheckList items={program.benefits.list} />
      </div>
    </Section>
  )
}
