import React from 'react'
import * as Yup from 'yup'
import Form from '../../molecules/Form'
import { sendFormData } from '../../../utils'

const FormCollaboratorsSchema = Yup.object().shape({
  intention: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  company: Yup.string(),
  message: Yup.string()
})

const initialValues = {
  intention: '',
  name: '',
  email: '',
  company: '',
  message: '',
  formType: 'WorkWithUs'
}

export default function FormCollaborators({ content }: { content: any }) {
  return (
    <Form
      content={content}
      initialValues={initialValues}
      validationSchema={FormCollaboratorsSchema}
      sendData={sendFormData}
    />
  )
}
