import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Company, { CompanyProps } from './Company'
import CompanyAction from './CompanyAction'
import styles from './index.module.css'

const query = graphql`
  query SectionCompaniesQuery {
    allCompaniesJson {
      edges {
        node {
          group
          description
          members {
            name
            logo {
              publicURL
            }
            description
            link
          }
        }
      }
    }
  }
`

interface CompanyGroup {
  group: string
  description: string
  members: CompanyProps[]
}

export default function SectionCompanies({
  toggleModal
}: {
  toggleModal: () => void
}) {
  const data = useStaticQuery(query)
  const groups = data.allCompaniesJson.edges

  function compare(a: any, b: any) {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  }

  function sortedMembers(members: any) {
    const sortedMembers = members.sort(compare)
    return sortedMembers.map((member: CompanyProps) => (
      <Company key={member.name} company={member} />
    ))
  }

  return (
    <section className={styles.section}>
      {groups.map(({ node }: { node: CompanyGroup }) => (
        <div className={styles.group} key={node.group}>
          <header className={styles.groupHeader}>
            <h2>{node.group}</h2>
            <p>{node.description}</p>
          </header>

          <div className={styles.members}>
            {sortedMembers(node.members)}
            <CompanyAction toggleModal={toggleModal} />
          </div>
        </div>
      ))}
    </section>
  )
}
