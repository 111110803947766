import React from 'react'
import classNames from 'classnames/bind'
import loadable from '@loadable/component'
import { useSpring, animated } from 'react-spring'
import slugify from '@sindresorhus/slugify'
import Button from '../../../atoms/Button'
import { ReactComponent as Arrow } from '../../../../images/arrow.svg'
import { ReactComponent as InfoIcon } from '../../../../images/info.svg'
import styles from './Company.module.css'

const Tippy = loadable(() => import('@tippyjs/react/headless'))
const cx = classNames.bind(styles)

export interface CompanyProps {
  name: string
  description: string
  logo: {
    publicURL: string
  }
  link?: string
}

const Info = ({ company }: { company: CompanyProps }) => (
  <aside className={styles.info}>
    <h3 className={styles.title}>{company.name}</h3>
    <p>{company.description}</p>
    {company.link && (
      <Button style="text" size="small" href={company.link}>
        Learn More <Arrow className={styles.arrow} />
      </Button>
    )}
  </aside>
)

// Forward ref for Tippy.js
const Content = React.forwardRef(
  ({ company }: { company: CompanyProps }, ref: any) => {
    const slug = slugify(company.name, {
      decamelize: false,
      separator: '_'
    })

    const classes = cx({
      company: true,
      [slug]: true
    })

    return (
      <div className={classes} ref={ref}>
        {company.logo && (
          <img
            className={styles.logo}
            src={company.logo.publicURL}
            alt={company.name}
          />
        )}

        <InfoIcon className={styles.infoIcon} />
      </div>
    )
  }
)

export default function Company({ company }: { company: CompanyProps }) {
  const config = { tension: 400, friction: 20 }
  const initialStyles = { transform: 'scale(0.5) translateY(-3rem)' }
  const [props, setSpring] = useSpring(() => initialStyles)

  function onMount() {
    setSpring({
      transform: 'scale(1) translateY(0)',
      onRest: () => null,
      config
    })
  }

  function onHide({ unmount }: { unmount: any }) {
    setSpring({
      ...initialStyles,
      onRest: unmount,
      config: { ...config, clamp: true }
    })
  }

  return (
    <Tippy
      interactive
      interactiveBorder={30}
      placement="bottom"
      trigger="mouseenter click focus"
      render={(attrs) => (
        <animated.div style={props}>
          <Info company={company} {...attrs} />
        </animated.div>
      )}
      animation
      onMount={onMount}
      onHide={onHide}
      fallback={
        <>
          <Content company={company} />
          <Info company={company} />
        </>
      }
    >
      <Content company={company} />
    </Tippy>
  )
}
