import React, { useState } from 'react'
import { Formik, Form as FormFormik, Field } from 'formik'
import Input from '../atoms/Input'
import Button from '../atoms/Button'
import Gdpr from '../atoms/Gdpr'
import Alert from '../atoms/Alert'

export interface FormFieldProps {
  label: string
  name: string
  type?: string
  options?: string[]
  required?: boolean
  help?: string
  placeholder?: string
  pattern?: string
  min?: string
}

export interface FormContent {
  title: string
  description: string
  success: string
  data: FormFieldProps[]
  gdpr: string
}

export interface FormProps {
  content: FormContent
  initialValues: { [key: string]: string }
  validationSchema: any
  sendData: (values: { [key: string]: string }) => Promise<{ success: boolean }>
}

export default function Form(props: FormProps) {
  const [success, setSuccess] = useState(false)
  const [hasError, setHasError] = useState(false)

  async function handleSubmit(values: { [key: string]: string }) {
    const status = await props.sendData(values)
    setSuccess(status ? status.success : false)
    setHasError(status ? false : status.success)
  }

  return success ? (
    <Alert severity="success">{props.content.success}</Alert>
  ) : (
    <Formik
      initialValues={props.initialValues}
      initialStatus="empty"
      validationSchema={props.validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await handleSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, isValid, status, setStatus }) => (
        <FormFormik onChange={() => status === 'empty' && setStatus(null)}>
          {props.content.data.map((field: FormFieldProps) => (
            <Field
              key={field.name}
              label={field.label}
              name={field.name}
              type={field.type || 'text'}
              options={field.options ? field.options : null}
              required={field.required}
              placeholder={field.placeholder}
              help={field.help}
              min={field.min}
              pattern={field.pattern}
              component={Input}
            />
          ))}

          <Button
            style="primary"
            type="submit"
            disabled={isSubmitting || !isValid || status === 'empty'}
          >
            Submit
          </Button>
          {hasError ? (
            <Alert severity="error">
              {' '}
              There was an error sending the form. Please try again later or
              contact the support team.
            </Alert>
          ) : (
            <></>
          )}
          <Gdpr text={props.content.gdpr} />
        </FormFormik>
      )}
    </Formik>
  )
}
