import React, { useState } from 'react'
import { PageProps, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SectionCompanies from '../components/pages/collaborators/SectionCompanies'
import SectionPartnerProgram from '../components/pages/collaborators/SectionPartnerProgram'
import FormCollaborators from '../components/pages/collaborators/FormCollaborators'
import Modal from '../components/atoms/Modal'

export default function PageGatsbyCollaborators(props: PageProps) {
  const {
    title,
    description,
    actions,
    program
  } = (props.data as any).content.edges[0].node.childCollaboratorsJson
  const form = (props.data as any).form.edges[0].node.childCollaboratorsJson

  const [isModalOpen, setIsModalOpen] = useState(false)

  function handleToggleModal() {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
      heroActions={actions}
    >
      <SectionCompanies toggleModal={handleToggleModal} />
      <SectionPartnerProgram
        program={program}
        toggleModal={handleToggleModal}
      />

      <Modal
        title={form.title}
        description={form.description}
        isOpen={isModalOpen}
        onToggleModal={handleToggleModal}
      >
        <FormCollaborators content={form} />
      </Modal>
    </Layout>
  )
}

export const contentQuery = graphql`
  query CollaboratorsPageQuery {
    content: allFile(
      filter: { relativePath: { eq: "pages/collaborators/index.json" } }
    ) {
      edges {
        node {
          childCollaboratorsJson {
            title
            description
            actions {
              title
              url
            }
            program {
              title
              description
              types {
                title
                description
              }
              benefits {
                title
                description
                list
                button
              }
            }
          }
        }
      }
    }

    form: allFile(
      filter: { relativePath: { eq: "pages/collaborators/form.json" } }
    ) {
      edges {
        node {
          childCollaboratorsJson {
            title
            description
            data {
              name
              label
              type
              options
              required
            }
            success
            gdpr
          }
        }
      }
    }
  }
`
