import React from 'react'
import stylesCompany from './Company.module.css'
import styles from './CompanyAction.module.css'

export default function CompanyAction({
  toggleModal
}: {
  toggleModal: () => void
}) {
  return (
    <button
      className={`${stylesCompany.company} ${styles.action}`}
      onClick={toggleModal}
    >
      <span aria-hidden="true">+</span>
      Your Company
    </button>
  )
}
